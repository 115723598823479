import { getI18n } from 'react-i18next';

import { AlertType } from '../../constants/alertTypes';
import { DataClassTypes } from '../../constants/dataClass';
import { DisplayInterval } from '../../constants/displayInterval';
import { NewPasswordForm } from '../../pages/other/Profile/interfaces/profile.interfaces';
import { NewUserForm } from '../../pages/other/Settings/interfaces/settings.interfaces';
import { localizationMapper } from '../localization';

import { APICore, User } from './apiCore';

type Warning = {
  level: number;
  quantityCode: string;
  quantityName: string;
  value: number;
  unit: string;
  color: string;
  name: string;
  lastDateTime: string;
};

export type LocationOnMap = {
  id: string;
  name: string;
  longitude: number;
  latitude: number;
  lastDateTimeFrom: string;
  warning?: Warning;
};

export type Location = LocationOnMap & {
  code: string;
  dataClasses: DataClassTypes[];
  clientId: string;
  dataSourceDescription: string | null;
};

export interface LocationFilterParams {
  locationId: string;
  showFilters: boolean;
  dataClass?: DataClassTypes;
  showInactive?: boolean;
  isFiltered?: boolean;
  filterFrom?: string;
  filterTo?: string;
  intervalType?: DisplayInterval;
  culture?: string | null;
  dataseries?: number[];
  filterType?: FilterType;
}

export interface GetDataSeriesParams {
  dataSerieId: number;
  filterFrom?: string;
  filterTo?: string;
  intervalType?: number;
  showInactive?: boolean;
  culture?: string | null;
}

export interface DataSerieFilterParams {
  dataSerieId: number;
  showInactive?: boolean;
  showSystem?: boolean;
  showNull?: boolean;
  isFiltered?: boolean;
  filterType?: FilterType;
  filterValue?: string;
  intervalType?: DisplayInterval;
  culture?: string | null;
}

export interface StaticFilesParams {
  clientCode?: string;
  chartCode?: string;
  culture?: string;
}

export interface MaintenanceAlert {
  id: string;
  type: AlertType;
  name: string;
  description: string;
  customDescription: string;
  clientId?: string;
  clientName?: string;
  locationId?: string;
  locationName?: string;
  startTime: Date;
  endTime?: Date;
  show?: boolean;
}
export enum FilterType {
  LAST_MEASUREMENTS = 'last-measurements',
  DAY = 'day',
  MONTH = 'month',
  YEAR = 'year',
  INTERVAL = 'interval',
}

const api = new APICore();

// locations map
function getLocationsMap(params: { clientId: string }) {
  const baseUrl = '/api/locations/map';
  return api.get(`${baseUrl}`, params);
}

// location data
function getLocationData(params: { locationId: string }) {
  const baseUrl = '/api/locations/location';
  return api.get(`${baseUrl}`, params);
}

// static files
function getStaticFiles(staticUrl: string, params: StaticFilesParams) {
  const baseUrl = '/api/staticFiles/' + staticUrl;
  return api.get(`${baseUrl}`, params);
}

// static files
function getRecommendations(params: StaticFilesParams) {
  const baseUrl = '/api/staticFiles/recommendations';
  return api.get(`${baseUrl}`, params);
}

function getExplanation(params: StaticFilesParams) {
  const baseUrl = '/api/staticFiles/explanation';
  return api.get(`${baseUrl}`, params);
}

function getLegend(params: StaticFilesParams) {
  const baseUrl = '/api/staticFiles/legend';
  return api.get(`${baseUrl}`, params);
}

function getPollutionClasses(params: StaticFilesParams) {
  const baseUrl = '/api/staticFiles/pollutionClasses';
  return api.get(`${baseUrl}`, params);
}

function getProjectIntroduction(params: StaticFilesParams) {
  const baseUrl = '/api/staticFiles/projectIntroduction';
  return api.get(`${baseUrl}`, params);
}

function getAppInformations() {
  const baseUrl = '/api/appVersion/get';
  return api.get(`${baseUrl}`, {});
}

//Excel export
function getExcelExport(params: LocationFilterParams) {
  const baseUrl = '/api/exportData/get';
  return api.getFile(`${baseUrl}`, params);
}

//get all clients
function getAllClients() {
  const baseUrl = '/api/clients/get';
  return api.get(`${baseUrl}`, {});
}

// get details about logged in user
function getCurrentLoggedInUser() {
  const baseUrl = '/api/Account/users/me';
  return api.get(`${baseUrl}`, {});
}

// change password
function changeMyPassword(params: NewPasswordForm) {
  const baseUrl = '/api/Account/users/changePassword/me';
  return api.update(`${baseUrl}`, params);
}

//change user password
function changeUserPassword(id: string, params: NewPasswordForm) {
  const baseUrl = '/api/Account/users/changeUserPassword/' + id;
  return api.update(`${baseUrl}`, params);
}

// get users
function getUsers() {
  const baseUrl = '/api/Account/users/';
  return api.get(`${baseUrl}`, {});
}

// get user
function getUser(id: string) {
  const baseUrl = '/api/Account/users/' + id;
  return api.get(`${baseUrl}`, {});
}

// get users by ClientId
function getUsersOfClient(clientId: string, isAdminUser: boolean) {
  if (isAdminUser) {
    return getUsers();
  }

  const baseUrl = '/api/Account/users/client/' + clientId;
  return api.get(`${baseUrl}`, {});
}

// add new user
function addNewUser(user: NewUserForm) {
  const baseUrl = '/api/Account/users/';
  return api.create(`${baseUrl}`, user);
}

// update user
function updateUser(user: User) {
  const baseUrl = '/api/Account/users/' + user.id;
  return api.update(`${baseUrl}`, user);
}

// delete user
function deleteUser(id: string) {
  const baseUrl = '/api/Account/users/' + id;
  return api.delete(`${baseUrl}`);
}

function getDataSerie(params: GetDataSeriesParams) {
  const baseUrl = '/api/dataseries/get';
  params.culture = localizationMapper(getI18n().language);
  return api.get(`${baseUrl}`, params);
}

function getAllDataSeries(params: LocationFilterParams) {
  const baseUrl = '/api/dataseries/getAll';
  params.culture = localizationMapper(getI18n().language);
  return api.get(`${baseUrl}`, params);
}

function getDataSerieData(params: DataSerieFilterParams) {
  const baseUrl = '/api/dataseries/getData';
  params.culture = localizationMapper(getI18n().language);
  return api.get(`${baseUrl}`, params);
}

function getMaintenanceAlerts(clientId?: string, locationId?: string) {
  const params = new URLSearchParams();
  if (clientId) params.append('clientId', clientId);
  if (locationId) params.append('locationId', locationId);
  return api.get('/api/maintenanceAlerts/get', { params });
}

export {
  getLocationsMap,
  getLocationData,
  getRecommendations,
  getExplanation,
  getLegend,
  getStaticFiles,
  getExcelExport,
  getAllClients,
  getCurrentLoggedInUser,
  changeMyPassword,
  getUsers,
  addNewUser,
  deleteUser,
  getUsersOfClient,
  getPollutionClasses,
  getProjectIntroduction,
  updateUser,
  getUser,
  getAppInformations,
  changeUserPassword,
  getDataSerie,
  getAllDataSeries,
  getDataSerieData,
  getMaintenanceAlerts,
};
